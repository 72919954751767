















import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Error extends Vue {
  private get isNotFound(): boolean {
    return this.code === '404';
  }

  private get hasTrackingId(): boolean {
    return this.trackingId !== undefined && this.trackingId.trim().length > 0;
  }

  private code: string = '';
  private message: string = '';
  private trackingId: string | undefined;

  private goBackHome(): void {
    this.$router.push('/');
  }

  private created(): void {
    this.code = this.$route.params.errorCode;
    // @ts-ignore
    this.trackingId = this.$route.query.trackingId;

    switch (this.code) {
      case '401':
        this.$router.go(0);
        break;
      case '403':
        this.message = 'Forbidden';
        break;
      case '404':
        this.message = 'Resource not found';
        break;
      default:
        this.message = 'Unhandled exception';
        break;
    }
  }
}
